define("discourse/plugins/discourse-formatting-toolbar/lib/discourse-markdown/formating_infoboxes", ["exports", "pretty-text/emoji"], function (_exports, _emoji) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  // Markdown support for special boxes

  function imageFor(code, opts) {
    code = code.toLowerCase();
    const url = (0, _emoji.buildEmojiUrl)(code, opts);
    if (url) {
      const title = `:${code}:`;
      const classes = (0, _emoji.isCustomEmoji)(code, opts) ? "emoji emoji-custom" : "emoji";
      return {
        url,
        title,
        classes
      };
    }
  }
  function BuildBox(emoji, state, tagInfo) {
    let info = imageFor(emoji, state.md.options.discourse);
    let token = state.push("html_raw", '', 0);
    token.content = `<p class="bbInfoBox-img"><img class="${info.classes} only-emoji" src="${info.url}"></img></p>`;
    token = state.push('div_open', 'div', 1);
    token.attrs = [['class', 'bbInfoBox-box']];
  }
  function BuildInfoBox(state, tagInfo) {
    BuildBox('bulb', state, tagInfo);
  }
  function BuildWaringBox(state, tagInfo) {
    BuildBox('warning', state, tagInfo);
  }
  function BuildQuestionBox(state, tagInfo) {
    BuildBox('question', state, tagInfo);
  }
  function BuildHelpBox(state, tagInfo) {
    BuildBox('help', state, tagInfo);
  }
  function BuildInfoBoxClose(state) {
    state.push('div_close', 'div', -1);
  }
  function setup(helper) {
    if (!helper.markdownIt) {
      return;
    }
    helper.whiteList(["div.bbInfoBox-box"]);
    helper.registerPlugin(md => {
      md.block.bbcode.ruler.push("info", {
        tag: "info",
        before: BuildInfoBox,
        after: BuildInfoBoxClose
      });
      md.block.bbcode.ruler.push("warning", {
        tag: "warning",
        before: BuildWaringBox,
        after: BuildInfoBoxClose
      });
      md.block.bbcode.ruler.push("help", {
        tag: "help",
        before: BuildHelpBox,
        after: BuildInfoBoxClose
      });
      md.block.bbcode.ruler.push("question", {
        tag: "question",
        before: BuildQuestionBox,
        after: BuildInfoBoxClose
      });
    });
  }
});